.success-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #086a91; /* Sky blue */
    color: #ffffff; /* Black text */
    padding: 40px; /* Adjust the value as per your preference */
}

.success-page .success-image {
    width: 200px; /* set the width of the image */
}

.success-page h1 {
    font-size: 2em;
    text-align: center;
}

.success-page p {
    font-size: 1.2em;
    text-align: center;
}