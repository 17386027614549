.App {
  text-align: left;
  display: flex;
  flex-direction: row;
  height: 500vh;
  background-color: #101017;
  
}



.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s linear;
  }
}

.App-logo {
  animation: App-logo-spin infinite 3s linear;
}

.App-logo-fast {
  animation: App-logo-spin-fast infinite 0.1s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin-fast {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}



.App-header,
.api-response-container {
  text-align: left;
  flex: 1;
  overflow-y: auto;
  background-color: #101017;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.api-response-box {
  padding: 10px;
  box-sizing: border-box;
  background-color: #282c34;
  color: white;
  font-size: 11px;
  height: 500%;
  overflow-y: auto;
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  justify-content: top; /* Add this */
  align-items: flex-start; /* Modify this */
  line-height: 1; /* Adjust this value as needed */
  flex: 1

}


.input-custom-textarea {
  width: 100%; /* Modify the width value as needed */
  height: 100px; /* Modify the height value as needed */
  font-size: 8px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.match {
  color: rgb(57, 165, 201);
  font-weight: bold;
}


.example-template-box {
  color: white;
  font-size: 10px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

}

.example-template-box h3 {
  color: #61dafb;
}

.api-response-box h1 {

  color: #61dafb;
}

.loading-text {
  color: white;
  opacity: 1;
  transition: opacity 1s;
}

.loading-text.fade-out {
  opacity: 0;
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.custom-react-select {
  align-items: center;
  
  color: rgb(80, 121, 175);
  font-size: 14px;
  /* Add any other desired styles */
}

.generate-report-button {
  width: 90%;
  height:50px;
  font-size: 18px;
}

.left-container-div{
 
  justify-content: center;
  align-items: center; 
  text-align: center;
  padding: 10%;
 

}