.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2dede; /* light red */
    color: #a94442; /* dark red */

}

.error-page h1 {
    font-size: 2em;
    text-align: center;
}

.error-page p {
    font-size: 1.2em;
    text-align: center;
}
