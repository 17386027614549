.signup {
    background-color: #001c3a;
    color: #f7f7f7bd;
    font-family: 'Helvetica';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}



.signup form {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    width: 200%;
    max-width: 300px;
}

.signup form input {
    padding: 10px;
    border: none;
    border-radius: 10px;
}

.signup form button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #6bccdf;
    color: #000;
    cursor: pointer;
}





@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.logo {
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    padding: 0px;
}

.logo-accelerated {
    width: 100px;
    height: 100px;
    animation: spin 0.1s linear infinite;
}
